if (Cookies.get('cookie_bar') === undefined) {
    if (window.location.pathname === '/' || window.location.pathname === '/en') {
        $('body').append('<div class="q-notifications__list-wrapper" id="cookie"><div class="q-notifications__list q-notifications__list--bottom fixed column items-end"><div class="q-notification row items-center text-white q-notification--multi-line doc-gdpr"><div class="row items-center col-all"><div class="q-notification__message col">En poursuivant votre navigation sur ce site, vous consentez à notre utilisation des cookies pour améliorer votre expérience en ligne.</div></div><div class="q-notification__actions row items-center col-all justify-end"><button tabindex="0" type="button" class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--rectangle text-yellow q-btn--actionable q-focusable q-hoverable q-btn--wrap"><div tabindex="-1" class="q-focus-helper"></div><div class="q-btn__wrapper col row q-anchor--skip"><div class="q-btn__content text-center col items-center q-anchor--skip justify-center row" id="cookie_button_accept"><div>Accept</div></div></div></button><a href="/privacy-policy" tabindex="0" class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--rectangle text-grey q-btn--actionable q-focusable q-hoverable q-btn--wrap"><div tabindex="-1" class="q-focus-helper"></div><div class="q-btn__wrapper col row q-anchor--skip"><div class="q-btn__content text-center col items-center q-anchor--skip justify-center row"><div>En savoir plus</div></div></div></button></div></div></div></div>')
    } else {
        $('body').append('<div class="cookie" id="cookie">En poursuivant votre navigation sur ce site, vous consentez à notre utilisation des cookies pour améliorer votre expérience en ligne. <a href="/privacy-policy">En savoir plus</a><div class="cookie_button" id="cookie_button_accept">OK</div></div>');
    }

    $("#cookie_button_accept").click(function (e) {
        e.preventDefault();
        $("#cookie").hide();
        Cookies.set('cookie_bar', "viewed", {expires: 30 * 13});
    });
}

//Code analytics
if(Cookies.get('cookie_bar') === "viewed"){
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-75898286-1', 'auto');
  ga('send', 'pageview');
}
