jQuery(function () {

    var larail = {

        // Define the name of the hidden input field for method submission
        methodInputName: '_method',
        // Define the name of the hidden input field for token submission
        tokenInputName: '_token',
        // Define the name of the meta tag from where we can get the csrf-token
        metaNameToken: 'csrf-token',

        initialize: function()
        {
            $('a[data-sm-method]').on('click', this.handleMethod);
        },

        handleMethod: function(e)
        {
            e.preventDefault();

            var link = $(this),
                httpMethod = link.data('sm-method').toUpperCase(),
                confirmMessage = link.data('sm-confirm'),
                form;

            // Exit out if there is no data-methods of PUT or DELETE.
            if ($.inArray(httpMethod, ['PUT', 'DELETE']) === -1)
            {
                return;
            }

            // Allow user to optionally provide data-confirm="Are you sure?"
            if (confirmMessage)
            {
                swal({
                  title: "Êtes-vous sûr?",
                  text: confirmMessage,
                  type: "warning",
                  showCancelButton: true,
                  cancelButtonText: "Annuler",
                  confirmButtonText: "Oui",
                  confirmButtonColor: "#DD6B55"
                }, function(isConfirm){
                  if(isConfirm) {
                    form = larail.createForm(link);
                    form.submit();
                  }
                });
            } else {
               form = larail.createForm(link);
               form.submit();
            }
        },

        createForm: function(link)
        {
            var form = $('<form>',
                {
                    'method': 'POST',
                    'action': link.prop('href')
                });

            var token =	$('<input>',
                {
                    'type': 'hidden',
                    'name': larail.tokenInputName,
                    'value': $('meta[name=' + larail.metaNameToken + ']').prop('content')
                });

            var method = $('<input>',
                {
                    'type': 'hidden',
                    'name': larail.methodInputName,
                    'value': link.data('sm-method')
                });

            return form.append(token, method).appendTo('body');
        }
    };

    larail.initialize();
});