$(document).ready(function () {
    $(".ToggleButton").on("click", function (e) {
        e.preventDefault();
        $(".OuterSlider").toggleClass("OuterSlider--open");
        $(".Slider").toggleClass("Slider--open");
    });

    $(".toggle-register-cards").on("click", function (e) {
        e.preventDefault();
        $("#email-register-card").toggle();
        $("#social-register-card").toggle();
    });

    $(".SelectedOption").on("click", function (e) {
        $(this).toggleClass("SelectedOption--open");
        $(".SelectOptions").toggleClass("SelectOptions--open");
    });

    $(".SelectedOption-mobile").on("click", function (e) {
        $(this).toggleClass("SelectedOption--open");
        $(".SelectOptions").toggleClass("SelectOptions--open");
    });

    const elinput = $(".search-input");
    const elicon = $(".icon");

    $(".search-box").on("mouseleave", function () {
        if (!elinput.is(":focus")) {
            elinput.removeClass("active");
            elicon.removeClass("active");
            elinput.val("");
            elinput.attr("placeholder", "");
        }
    });

    $(".search-box").on("mouseenter", function () {
        elinput.addClass("active");
        elicon.addClass("active");
        elinput.attr("placeholder", $("#search-input-placeholder").text());
    });

    elinput.on("focusout", function () {
        elinput.removeClass("active");
        elicon.removeClass("active");
        elinput.val("");
        elinput.attr("placeholder", "");
    });

    $(function () {
        var min_price = 0;
        var max_price = 500;
        if (getUrlParameter("min_price_filter")) {
            min_price = getUrlParameter("min_price_filter");
        }
        if (getUrlParameter("max_price_filter")) {
            max_price = getUrlParameter("max_price_filter");
        }
        $("#slider-range").slider({
            range: true,
            min: 0,
            max: 1000,
            values: [min_price, max_price],
            slide: function (event, ui) {
                $("#min_price_filter").val(ui.values[0]);

                $("#max_price_filter").val(ui.values[1]);
                $(ui.handle)
                    .find(".price-filter-ui-slider-tooltip")
                    .text(ui.value);
            },
            stop: function (event, ui) {},
            start: function (event, ui) {
                $(ui.handle).find(".price-filter-ui-slider-tooltip").show();
            },
            create: function (event, ui) {
                var tooltip = $(
                    '<div class="price-filter-ui-slider-tooltip" />'
                ).css({
                    position: "absolute",
                    color: "#000",
                    top: 28,
                    left: 5,
                });

                $(event.target).find(".ui-slider-handle").append(tooltip);
            },
        });
        $(".price-filter-ui-slider-tooltip").each(function (index) {
            $(this).text($("#slider-range").slider("values", index));
        });

        $("#min_price_filter").val($("#slider-range").slider("values", 0));

        $("#max_price_filter").val($("#slider-range").slider("values", 1));
    });

    function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split("&"),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split("=");

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined
                    ? true
                    : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    }

    function resizeSelect() {
        $("#width_tmp_option").html($(this).find(":selected").text());
        const parentWidth = $(this).closest(".custom_select").parent().width();
        const labelWidth = $(this)
            .closest(".custom_select")
            .find("label[for =" + $(this).attr("id") + "]")
            .width();
        $(this).width();
        $(this).css({
            width: $("#width_tmp_select").width(),
            "max-width": parentWidth - labelWidth,
        });
    }

    $(".resizing_select").on("change", resizeSelect);

    $(".resizing_select").each(resizeSelect);

    $(".toggle-favorites-form").on("submit", function (e) {
        e.preventDefault();

        var form = $(this);
        var submitButton = form.find('*[type="submit"]');
        var iconTag = submitButton.children();
        $.ajax({
            type: "POST",
            data: form.serialize(),
            url: form.prop("action"),
            beforeSend: function () {},
            success: function (response) {
                iconTag.css("color", response.isExist ? "#ff7900" : "#B0B0B0");
            },
            error: function (error) {
                console.log("Error");
            },
        });
    });

    $("#favorites-filter")
        .find('*[type="checkbox"]')
        .on("change", function (e) {
            e.preventDefault();
            let isChecked = $(this)[0].checked;
            // console.log(isChecked);
            $("#favorites-filter").trigger("submit");
        });

    $(window).on("resize", function () {
        // console.log("resized");
        $(".resizing_select").each(resizeSelect);
    });
});
